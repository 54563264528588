@tailwind base;

/* Your own custom base styles */
.container {
  max-width: 1220px !important;
}

h2 {
  @apply text-xl;
}
/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

.slick-slide {
  padding: 0 15px;
  float: none !important;
  height: auto !important;
}

.slick-slide > div {
  height: 100%;
}

.slick-list {
  margin: 0 40px !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 40px !important;
  margin: 0 0 0 -4px !important;
}

.slick-track {
  display: flex !important;
}

.slick-dots {
  position: relative !important;
}

.slick-dots li button {
  display: flex !important;
  align-items: center !important;
}

.slick-dots li button:before {
  font-size: 13px !important;
  color: rgb(187, 98, 195) !important;
}

.slick-dots .slick-active button:before {
  font-size: 20px !important;
  color: rgb(134 25 143) !important;
}

@media (max-width: 500px) {
  .slick-slide {
    padding: 0px 5px !important;
  }

  .slick-list {
    margin: 0 5px !important;
  }
}
